import React, { useEffect, useState } from 'react';
import { MdOutlineMoreVert, MdOutlineRemoveRedEye, MdOutlineTouchApp, MdOutlineWatchLater } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import { AlertDeletePopupModal } from "../Components/PopupModal/AlertDeletePopupModal";
import { DeletePopupModal, EditDp, FormParser } from "../Components";
import axiosConfig from "../Service/axiosConfig";
import { UpgradePage } from "./UpgradePage";
import { Modal } from 'react-bootstrap';
import Masters from "../Components/masters.json"
import { CraftsModal } from './CraftsModal';

const AudirequireShare = ({ Audition, index, HandleDeleteClose }) => {
    const Navigate = useNavigate();
    const profile_data = JSON.parse(localStorage.getItem('profile_data'));
    const [loading, setLoading] = useState(false);
    const [AlertDeletePopupType, SetAlertDeletePopupType] = useState("");
    const [deletePopupType, setDeletepopupType] = useState("");
    const [RowId, SetRowId] = useState();
    const [AlertDeletePopupType1, SetAlertDeletePopupType1] = useState("");
    const [deletePopupType1, setDeletepopupType1] = useState("");
    const [RowId1, SetRowId1] = useState();
    const [UpgradeShow, setUpgradeShow] = useState(false);
    const [ScreenVisbile, setScreenVisbile] = useState("plans");
    const [auditionRequirements, setAuditionRequirements] = useState([]);
    const [showApply, setshowApply] = useState(false)
    const [profilePic, setProfilepic] = useState(false)
    const handleUpgradeClose = () => {
        setUpgradeShow(false);
        setScreenVisbile("plans");
    };

    const HandleUpgrade = () => {
        setUpgradeShow(true);
    };

    const handleLoginShow = () => {
        document.querySelector('.login-text').click();
    };

    const DeleteAlert = (id) => {
        SetRowId(id);
        SetAlertDeletePopupType("success");
    };

    const CancelAlerrt = () => {
        SetAlertDeletePopupType("error");
    };

    const HandleDelete = (id) => {
        console.log(id);
        axiosConfig.delete(`/accounts/audition-requirements/${id}/`).then(res => {
            console.log(res);
            setDeletepopupType("success");
            SetAlertDeletePopupType("error");
            HandleDeleteClose();
        }).catch(error => {
            console.log(error);
        });
    };
    const handleprofilpicclose = () => {
        setProfilepic(false)
        // GetData()
    }
    const handleClose = () => {
        setshowApply(false)
    }
    const HandleProfilepic = () => {
        if (profile_data?.profile_pic === null) {
            setProfilepic(true)
        } else if (localStorage.getItem('is_24_craft') === null) {
            setshowApply(true)
        }
        else {
            HandleUpgrade();
        }
    }
    const DeleteAlert1 = (id) => {
        if (localStorage.getItem('token')) {
            SetRowId1(id);
            if (localStorage.getItem('is_pro') === "true") {
                SetAlertDeletePopupType1("success");
            } else {
                HandleProfilepic()
            }
        } else {
            handleLoginShow();
        }
    };

    const CancelAlerrt1 = () => {
        SetAlertDeletePopupType1("error");
    };

    const handleApply = (id) => {
        axiosConfig.post("/accounts/audition-applications/", { audition_requirement: id }).then(res => {
            console.log(res);
            setDeletepopupType1("success");
            SetAlertDeletePopupType1("error");
        }).catch(error => {
            console.log(error);
        });
    };

    const getProjectType = (status) => {
        switch (status) {
            case 'Movies':
                return '#f5d8ff';
            case 'OTT':
                return '#c1c1cc';
            case 'TV_Serials':
                return '#ffd8b1';
            case 'Web_Series':
                return '#b3ecff';
            case 'Music_Albums':
                return '#f0e68c';
            case 'Cover_Songs':
                return '#ffcccb';
            case 'Item_Song':
                return '#ff69b4';
            case 'Short_Films':
                return '#b2fab4';
            case 'Ads':
                return '#ffdab9';
            case 'Modelling':
                return '#e0ffff';
            case 'Audition':
                return '#ffb6c1';
            default:
                return '#ffffff';
        }
    };

    const getProjectTypeColor = (status) => ({
        Movies: '#8b008b', OTT: '#696969', TV_Serials: '#8b4513',
        Web_Series: '#4682b4', Music_Albums: '#bdb76b', Cover_Songs: '#a52a2a',
        Item_Song: '#c71585', Short_Films: '#228b22', Ads: '#d2691e',
        Modelling: '#20b2aa', Audition: '#db7093'
    }[status] || '#ffffff');

    const handleNavigate = (id, type) => {
        if (type === "view") {
            Navigate(`/auditions/${id}`);
        } else {
            Navigate(`/auditions/${id}/edit`);
        }
    };

    const shareContent = async (share_to, data) => {
        try {
            let baseUrl = `${window.location.protocol}//${window.location.host}`;
            let URL = `${baseUrl}/audi-requirements/${data.id}/`;
            let text = `${data?.character ? `Required ${data.character}` : ""} ${data.audition?.project_type ? `for ${data.audition.project_type}` : ""} ${data?.gender ? `- (Gender : ${data.gender})` : ""} ${data?.from_age ? `- Age(${data.from_age}-${data.to_age})yrs` : ""} ${data?.loc_lang?.languages ? `- (Languages : ${data.loc_lang.languages})` : "- Languages : Any Language"} ${data?.loc_lang?.state ? `- (Location : ${data?.loc_lang?.state})` : " - Location : Any Location"} ${data.audition?.banner_name ? `- (Banner : ${data.audition.banner_name})` : ""} ${URL}`;
            const shareUrl = encodeURIComponent(text);

            if (share_to === "whatsapp") {
                window.open(`https://api.whatsapp.com/send?text=${shareUrl}`, '_blank');
            } else if (share_to === "fb") {
                window.open(`https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`, '_blank');
            } else if (navigator.share) {
                await navigator.share({
                    title: 'Share Post',
                    text
                });
            }
        } catch (err) {
            console.error('Failed to share content:', err);
        }
    };

    const copyToClipboard = async (data) => {
        try {
            let baseUrl = `${window.location.protocol}//${window.location.host}`;
            let shareUrl = `${baseUrl}/audi-requirements/${data.id}`;
            await navigator.clipboard.writeText(
                `${data?.character ? `Required ${data.character}` : ""} ${data.audition?.project_type ? `for ${data.audition.project_type}` : ""} ${data?.gender ? `- (Gender : ${data.gender})` : ""} ${data?.from_age ? `- Age(${data.from_age}-${data.to_age})yrs` : ""} ${data?.loc_lang?.languages ? `- (Languages : ${data.loc_lang.languages})` : "- Languages : Any Language"} ${data?.loc_lang?.state ? `- (Location : ${data?.loc_lang?.state})` : " - Location : Any Location"} ${data.audition?.banner_name ? `- (Banner : ${data.audition.banner_name})` : ""} ${shareUrl}`
            );
            console.log('Text copied to clipboard!');
        } catch (err) {
            console.error('Failed to copy text to clipboard:', err);
        }
    };
    const fetchAuditionList = async (profile_data, id) => {
        setLoading(true);
        try {
            const response = await axiosConfig.get(`accounts/audition-applications/?profile=${profile_data?.profile_id}&audition_requirement=${Audition?.id}&application_status=`);
            const auditionRequirements = response.data.results.map(item => item.audition_requirement);
            setAuditionRequirements(auditionRequirements);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        if (profile_data) {
            fetchAuditionList(profile_data)
        }
    }, [Audition])
    function timeAgo(dateString) {
        const now = new Date();
        const pastDate = new Date(dateString);
        const secondsAgo = Math.floor((now - pastDate) / 1000);
        const minutesAgo = Math.floor(secondsAgo / 60);
        const hoursAgo = Math.floor(minutesAgo / 60);
        const daysAgo = Math.floor(hoursAgo / 24);

        if (daysAgo > 0) return `${daysAgo}d ago`;
        if (hoursAgo > 0) return `${hoursAgo}hr${hoursAgo > 1 ? 's' : ''} ago`;
        if (minutesAgo > 0) return `${minutesAgo}min${minutesAgo > 1 ? 's' : ''} ago`;
        return `${secondsAgo}s`;
    }
    const [Data, setData] = useState({})
    const [requirementShow, setRequirementShow] = useState(false)
    const HandleEdit = (id) => {
        setData({})
        axiosConfig.get(`/accounts/audition-requirements/${id}/`).then(res => {
            console.log(res.data)
            setData({
                ...res.data,
            })
            setRequirementShow(true)
        }).catch(error => {
            console.log(error)
        })
    }
    const AudiRequirements = (data) => {
        console.log(Data)
        const orderTopost = {
            ...data,
            audition: Data.audition?.id
        }
        console.log(orderTopost)
        axiosConfig.patch(`/accounts/audition-requirements/${Data.id}/`, orderTopost).then(res => {
            console.log(res)
            HandleDeleteClose()
            setRequirementShow(false)
        }).catch(error => {
            console.log(error.response.data)
            // setpopupType("error");
        })
    }
    return (
        <>
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12" key={index} >
                <div className="card card-style" style={{ backgroundColor: getProjectType(Audition.audition?.project_type) }}>
                    <div className="card-type card-types" style={{ backgroundColor: getProjectTypeColor(Audition.audition?.project_type), position: "relative" }}>{Audition.audition?.project_type ? Audition.audition?.project_type : "---"}</div>
                    <div className="dropdown audi-edit">
                        <MdOutlineMoreVert data-bs-toggle="dropdown" size={25} />
                        <ul className="dropdown-menu">
                            {Audition?.audition?.profile?.id === JSON.parse(localStorage.getItem('profile_data'))?.profile_id &&
                                <>
                                    <li><a className="dropdown-item" onClick={() => { HandleEdit(Audition.id) }}>edit</a></li>
                                    <li><a className="dropdown-item" onClick={() => DeleteAlert(Audition?.id)}>Delete</a></li>
                                </>
                            }
                            <li><a className="dropdown-item" onClick={() => copyToClipboard(Audition)}>Copy URL</a></li>
                            <li><a className="dropdown-item" onClick={() => shareContent("share", Audition)}>Share</a></li>
                        </ul>
                    </div>
                    <div className='card-content'>
                        {Audition?.audition?.banner_name ? (
                            <h4 className="banner-title" style={{ textAlign: "center" }}>
                                {Audition?.audition?.banner_name}
                            </h4>
                        ) : (
                            <div style={{ padding: '20px' }}></div>
                        )}

                        <div className="card-descrt">
                            <div onClick={() => handleNavigate(Audition?.audition.id, "view")} className="card-descrt-inner">
                                <div style={{ color: "#000" }} className="project_title">
                                    <div className="audi-id">AD{Audition?.id && Audition?.id}</div>
                                    <div>
                                        <div className='Audi-project-title'>{Audition?.audition?.project_title && Audition?.audition?.project_title}</div>
                                        <div className='Audi-description'>{Audition?.audition?.description && Audition?.audition?.description}</div>
                                    </div>
                                </div>
                                <div className="card-audi">
                                    {Audition?.audition?.platform &&
                                        <div className="audi-label" style={{ background: '#b00de9' }}>{Audition?.audition?.platform}</div>
                                    }
                                    {Audition?.audition?.zoner &&
                                        <div className="audi-label" style={{ background: '#671640' }}>{Audition?.audition?.zoner}</div>
                                    }
                                    {Audition?.audition?.loc_lang?.city &&
                                        <div className="audi-label" style={{ background: '#3cb371' }}>{Audition?.audition?.loc_lang?.city}</div>
                                    }
                                    {Audition?.audition?.loc_lang?.languages &&
                                        <div className="audi-label" style={{ background: '#db7093' }}>{Audition?.audition?.loc_lang?.languages}</div>
                                    }
                                </div>
                                <div className="d-flex justify-content-between card-detail">
                                    <div className="card-botton-centent" style={{ fontWeight: "800" }}>
                                        <div className="card-cont-title">Producer</div>
                                        <div className='card-cont-detail'>{Audition?.audition?.producer_name ? Audition?.audition?.producer_name : "-"}</div>
                                    </div>
                                    <div className="card-botton-centent" style={{ fontWeight: "800" }}>
                                        <div className="card-cont-title">Director</div>
                                        <div className='card-cont-detail'>{Audition?.audition?.director_name ? Audition?.audition?.director_name : "-"}</div>
                                    </div>
                                    {Audition?.audition?.hero && <div className="card-botton-centent" style={{ fontWeight: "800" }}>
                                        <div className="card-cont-title">Hero</div>
                                        <div className='card-cont-detail'>{Audition?.audition?.hero ? Audition?.audition?.hero : "-"}</div>
                                    </div>}
                                </div>
                                <div className="">
                                    <div className="card-title-custom">Role - {Audition.character}</div>
                                    <div className="applictn-dtls">
                                        {Audition?.gender && (
                                            <div className="row">
                                                <div className="apply-content col-6"><span>Gender</span><span>:</span></div>
                                                <div className="col-6 apply-data">{Audition.gender}</div>
                                            </div>)}
                                        {Audition?.from_age &&
                                            <div className="row">
                                                <div className="apply-content col-6"><span>Age</span><span>:</span></div>
                                                <div className="col-6 apply-data">{Audition.from_age} - {Audition.to_age} years</div>
                                            </div>}
                                        {Audition?.budget &&
                                            <div className="row">
                                                <div className="apply-content col-6"><span>Budget</span><span>:</span></div>
                                                <div className="col-6 apply-data">{Audition.budget ? Audition.budget : 0}</div>
                                            </div>}
                                        {Audition?.no_of_requirements &&
                                            <div className="row">
                                                <div className="apply-content col-6"><span>No. of Positions</span><span>:</span></div>
                                                <div className="col-6 apply-data">{Audition.no_of_requirements}</div>
                                            </div>}
                                        <div className="row">
                                            <div className="apply-content col-6"><span>Preffered Languages</span><span>:</span></div>
                                            <div className="col-6 apply-data">{Audition?.loc_lang?.languages ? Audition?.loc_lang?.languages : "Any Language"}</div>
                                        </div>
                                        <div className="row">
                                            <div className="apply-content col-6"><span>Preffered Location</span><span>:</span></div>
                                            <div className="col-6 apply-data">{Audition?.loc_lang?.city
                                                ? Audition.loc_lang.city : Audition?.loc_lang?.state ? Audition.loc_lang.state : "Any Location"
                                            }</div>
                                            {/* <div className="col-6 apply-data">{Audition?.loc_lang?.city ? Audition?.loc_lang?.city : "Any Location"}</div> */}
                                        </div>
                                        {Audition?.specification &&
                                            <div className="row auditionList">
                                                {/* <div className="apply-content col-6"><span>Specification</span><span>:</span></div> */}
                                                <div className="apply-data">{Audition.specification}</div>
                                            </div>}
                                    </div>
                                </div>
                            </div>
                            <div className="card-bottom">
                                <div className="card-bottom-inner">
                                    <div className="card-bottom-left">
                                        <div className='require-conten'>
                                            <div className="card-cont-detail d-flex align-items-center"><span className="card-cont-title"><MdOutlineWatchLater /></span> : <span style={{ marginLeft: "2px" }}>{timeAgo(Audition?.created_at)}</span></div>
                                            <div className="card-cont-detail d-flex align-items-center"><span className="card-cont-title"><MdOutlineRemoveRedEye /></span> : <span style={{ marginLeft: "2px" }}>{Audition?.audition?.views}</span></div>
                                        </div>
                                        <div className="card-cont-detail d-flex align-items-center"><span className="card-cont-title">Applicants</span> : <span style={{ marginLeft: "2px" }}>{Audition.applications}</span></div>
                                    </div>
                                    <div className="card-bottom-right">
                                        <div className="require-text">
                                            {/* <div>{Audition?.requirement_status ? "Accepting Applications" : "closed for applications"}</div> */}
                                            {auditionRequirements?.includes(Audition.id) ? (
                                                <>
                                                    <button type="button" className="btn btn-primary" disabled>Applied</button>
                                                </>
                                            ) : (
                                                <>
                                                    <button type="button" className="btn btn-primary" onClick={() => DeleteAlert1(Audition.id)}>
                                                        <MdOutlineTouchApp /> Apply
                                                    </button>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div >
                </div >
            </div>
            {UpgradeShow &&
                <UpgradePage UpgradeShow={UpgradeShow} setUpgradeShow={setUpgradeShow} ScreenVisbile={ScreenVisbile} setScreenVisbile={setScreenVisbile} handleUpgradeClose={handleUpgradeClose} RowId1={RowId1} />
            }
            {
                deletePopupType !== "" &&
                <DeletePopupModal setDeletepopupType={setDeletepopupType} deletePopupType={deletePopupType} message={"Delete Audition SuccessFully!"} />
            }
            {
                AlertDeletePopupType === "success" &&
                <AlertDeletePopupModal AlertDeletePopupType={AlertDeletePopupType} SetAlertDeletePopupType={SetAlertDeletePopupType} HandleDelete={HandleDelete} CancelAlerrt={CancelAlerrt} row={RowId} />
            }
            {
                deletePopupType1 !== "" &&
                <DeletePopupModal setDeletepopupType={setDeletepopupType1} deletePopupType={deletePopupType1} message={"Applied for Audition SuccessFully!"} />
            }
            {
                AlertDeletePopupType1 === "success" &&
                <AlertDeletePopupModal AlertDeletePopupType={AlertDeletePopupType1} SetAlertDeletePopupType={SetAlertDeletePopupType1} HandleDelete={handleApply} CancelAlerrt={CancelAlerrt1} row={RowId1} message={"Are You Sure You Want Apply for this Audition Requirement ?"} />
            }
            <Modal
                size="lg"
                show={requirementShow}
                onHide={() => setRequirementShow(false)}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Add Audition Requirements
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FormParser modelObject={Masters.AditionRequirements} formData={Data} formSubmit={AudiRequirements} error={Error} />
                </Modal.Body>
            </Modal>
            <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" centered show={profilePic} onHide={handleprofilpicclose} className="edit-profile-popup">
                <Modal.Header closeButton>
                    <Modal.Title>Add your profile to get started</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <EditDp handleLoginEvent={handleprofilpicclose} showApply={showApply} setshowApply={setshowApply} />
                </Modal.Body>
            </Modal>
            {showApply &&
                <CraftsModal showApply={showApply} setshowApply={setshowApply} handleClose={handleClose} />
            }
        </>
    );
};

export { AudirequireShare };
