import React, { useEffect, useState } from 'react';
import { Modal } from "react-bootstrap";
import { FormParser } from '../Components/FormParser/FormParser';
import Masters from "../Components/masters.json";
import { useAuth } from '../Utils/AuthContext';
import { useNavigate, useSearchParams } from "react-router-dom";
import axiosConfig from "../Service/axiosConfig";
import { PopupModal } from "../Components";
import { AudirequireShare } from '.';

const AudiRequire = ({ ProfileData }) => {
    console.log(ProfileData?.id, "profileData");
    const { isLoggedIn, is24Craft } = useAuth();
    const profile_data = JSON.parse(localStorage.getItem('profile_data'))?.profile_id;
    const frames_24 = localStorage.getItem('frames_24');
    const [searchParams, setSearchParams] = useSearchParams();
    const [popupType, setpopupType] = useState("");
    const [Error, SetError] = useState("");
    const [loading, setLoading] = useState(false);
    const [AudiData, setAudiData] = useState([]);
    const [filterShow, setFilterShow] = useState(false);
    const [params, setParams] = useState({
        requirement_status: searchParams.get("requirement_status") || "live",
        // audition__profile: searchParams.get("audition__profile") || '',
        state: searchParams.get('present_state') || "",
        city: searchParams.get('present_city') || "",
        languages: searchParams.get('languages'),
        audition__project_type: searchParams.get('audition__project_type') || "",
        audition__platform: searchParams.get('audition__platform') || "",
        gender: searchParams.get('gender') || "",
    });
    const [isMobile, setIsMobile] = useState(window.innerWidth < 1020);
    const [totalPages, settotalPages] = useState();
    const [loading2, setLoading2] = useState("");
    const handleResize = () => {
        setIsMobile(window.innerWidth < 1020);
    };
    let page = 1;
    const Navigate = useNavigate();
    const handleNavigate = (id) => {
        Navigate(`/auditions/add`);
    };

    const formSubmit = (Data) => {
        setFilterShow(false);
        const datatosend = {
            ...params,
            ...Data
        };
        const filteredParams = Object.keys(datatosend)
            .filter(key => datatosend[key] !== null && datatosend[key] !== "")
            .reduce((acc, key) => {
                acc[key] = datatosend[key];
                return acc;
            }, {});
        let queryStringParts = [];
        Object.keys(filteredParams).forEach(key => {
            const value = filteredParams[key];
            if (Array.isArray(value) && value.length > 0) {
                value.forEach(arrayValue => {
                    queryStringParts.push(`${encodeURIComponent(key)}=${encodeURIComponent(arrayValue)}`);
                });
            } else {
                queryStringParts.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
            }
        });
        const queryString = queryStringParts.join('&');
        setSearchParams(queryString);
        setParams(filteredParams);
        console.log(filteredParams, "filtered", queryString, "query", queryStringParts)
        axiosConfig.get(`/accounts/audition-requirements/?page_size=20&page=${page}&${queryString}`)
            .then(response => {
                settotalPages(Math.ceil(response.data.count / 20));
                setAudiData(response.data.results);
                setLoading(false);
            })
            .catch(error => {
                console.log(error.response.data.error);
            });
    };

    const GetData = async (status, profile) => {
        setLoading(true);

        // Filter out null or empty values from params
        const filteredParams = Object.keys(params)
            .filter(key => params[key] !== null && params[key] !== "")
            .reduce((acc, key) => {
                acc[key] = params[key];
                return acc;
            }, {});

        try {
            const response = await axiosConfig.get(`/accounts/audition-requirements/?requirement_status=${status}`);

            settotalPages(Math.ceil(response.data.count / 20));
            setAudiData(response.data.results);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };

    const handleInfiniteScroll = async () => {
        try {
            if (
                window.innerHeight + document.documentElement.scrollTop + 1 >=
                document.documentElement.scrollHeight
            ) {
                page = page + 1;
                console.log(page);
                if (page <= totalPages && !loading2) {
                    setLoading2(true);
                    const resp = await axiosConfig.get(`/accounts/audition-requirements/?page=${page}&requirement_status=${params.status}`);
                    setAudiData((Data) => [...Data, ...resp.data.results]);
                    setLoading2(false);
                }
            }
        } catch (error) {
            console.log(error);
            setLoading2(false);
        }
    };

    const handleStatusChange = (newStatus) => {
        const newParams = { ...params, requirement_status: newStatus };
        console.log(newParams);
        setParams(newParams);
        setSearchParams(newParams);
        GetData(newStatus);
    };

    useEffect(() => {
        GetData(params.requirement_status);
    }, []);

    useEffect(() => {
        window.addEventListener('scroll', handleInfiniteScroll);
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('scroll', handleInfiniteScroll);
            window.removeEventListener("resize", handleResize);
        };
    }, [totalPages]);

    const HandleDeleteClose = () => {
        GetData(params.requirement_status);
    };

    return (
        <>
            <div style={{ marginTop: "50px" }} className='main-cards'>
                <div className="opportunities">
                    <div className="row justify-content-between">
                        <div className="col-md-7">
                            <h6 className="opp-title">Opportunities</h6>
                            <p className="opp-quote">Continue your star journey</p>
                        </div>
                        <div className="col-md-5">
                            <div className="btnflx">
                                {frames_24?.split(',')?.some(role =>
                                    ["Executive Producer", "Casting Director", "Producer", "Director", "Associate Director", "Co-Director", "Assistant Director"].includes(role.trim())
                                ) && isLoggedIn && (ProfileData ? ProfileData.id === profile_data : profile_data) && (
                                        <div className="btnitem">
                                            <button className="btn btn-primary" onClick={handleNavigate}>+ Add New</button>
                                        </div>
                                    )}
                            </div>
                        </div>
                    </div>
                    <div className="Audi-status">
                        <div className="auditons-status-btns">
                            <button className={`btn ${params.requirement_status === "live" ? "btn-secondary" : "btn-info"}`} onClick={() => handleStatusChange("live", "")}>Active</button>
                            <button className={`btn ${params.requirement_status === "closed" ? "btn-secondary" : "btn-info"}`} onClick={() => handleStatusChange("closed", "")}>Past</button>
                        </div>
                        <div className="audi-filter">
                            <button className="btn btn-secondary" onClick={() => setFilterShow(true)}>Filter by</button>
                        </div>
                    </div>
                </div>
                <div className="opportunity-card" style={{ marginTop: '50px' }}>
                    <div className="row">
                        {AudiData.map((Audition, index) => (
                            <AudirequireShare Audition={Audition} index={index} HandleDeleteClose={HandleDeleteClose} />
                        ))}
                    </div>
                </div>
            </div>
            {
                popupType !== "" &&
                <PopupModal popupType={popupType} setpopupType={setpopupType} error={Error} />
            }
            <Modal
                size="lg"
                show={filterShow}
                onHide={() => setFilterShow(false)}
                aria-labelledby="example-modal-sizes-title-lg"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Filters
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FormParser formData={params} modelObject={Masters.AuditionFilter} formSubmit={formSubmit} error={Error} />
                </Modal.Body>
            </Modal>
        </>
    );
};

export { AudiRequire };
