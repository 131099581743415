import React, { useState, useEffect } from "react";
import "./CustomTop.css";
import { useLocation, NavLink } from "react-router-dom";
import { BiAddToQueue } from "react-icons/bi";
import { MdOutlineHome, MdOutlineNotifications, MdOutlineMovieCreation } from "react-icons/md";
import { useAuth } from "../Utils/AuthContext";
import { useNavigate } from "react-router";
import Modal from 'react-bootstrap/Modal';
import { Login, Signup } from "../Components/Login";
import { NewPost } from "./NewPost";
import { Notifications } from "./Notifications";
import { RiAccountCircleLine } from "react-icons/ri";
import { Tab, Tabs } from "react-bootstrap";

const CustomMobile = () => {
    const location = useLocation();
    const Navigate = useNavigate();
    const { setIsLoggedIn, isLoggedIn, setIs24Craft, is24Craft } = useAuth();
    const [show, setShow] = useState(false);
    const [loginShow, setloginShow] = useState(false);
    const [loginView, setloginView] = useState("login");
    const handleLoginClose = () => setloginShow(false);
    const handleLoginShow = () => setloginShow(true);
    const [ProfileData, setProfileData] = useState({})
    const [isRead, setIsRead] = useState(false);
    const [page, setPage] = useState(1);
    const [AddPost, setAddPost] = useState(false)
    const [key, setKey] = useState('Login');

    const setProfile = () => {
        setProfileData(JSON.parse(localStorage.getItem("profile_data")))
    }
    const ActiveData = (activeUrl) => {
        setIsLoggedIn(false);
        localStorage.setItem("activeurl", activeUrl)
    }
    const hcc_id = localStorage.getItem('hcc_id');
    const MobileLogin = () => {
        Navigate(`/profile/${hcc_id}`)
    }
    function handleLoginEvent() {
        handleLoginClose();
        setIsLoggedIn(true);
        setProfile()
        if (localStorage.getItem('is_24_craft')) {
            setIs24Craft(true)
        }
    }
    const CheckLogin = async () => {
        if (localStorage.getItem('token')) {
            setIsLoggedIn(true)
            setProfile()
        }
        if (localStorage.getItem('is_24_craft')) {
            setIs24Craft(true)
        }
    }
    useEffect(() => {
        setProfile();
        CheckLogin();
        if (location.pathname === '/signup') {
            if (localStorage.getItem("token")) {
                Navigate("/")
                setloginView("")
                setloginShow(false)
            } else {
                console.log("test")
                setloginView("signup")
                setKey("Signup")
                setloginShow(true)
            }
            // setloginView("signup")
        }
    }, [])
    const handleClose = () => { setShow(false); setIsRead(false) }
    const handleShow = () => { setShow(true); setPage(1) }
    useEffect(() => {
        if (key === 'Login') {
            setloginView('login');
        } else if (key === 'Signup') {
            setloginView('signup');
        }
    }, [key]);
    return (
        <>

            <div className="options-mobile">
                <div className="options-container-mobile">
                    <div className="mobile-icons-tab" onClick={() => Navigate('/')}>
                        <MdOutlineHome className="option-icon" />
                        <p>Home</p>
                    </div>
                    <div className="mobile-icons-tab" onClick={() => Navigate('/auditions')}>
                        <MdOutlineMovieCreation className="option-icon" />
                        <p>Auditions</p>
                    </div>
                    {isLoggedIn ?
                        <>
                            {is24Craft && <div className="mobile-icons-tab" onClick={(e) => { setAddPost(true); }}>
                                <BiAddToQueue className="option-icon" />
                                <p>New Post</p>
                            </div>}
                            <div className="mobile-icons-tab" onClick={handleShow}>
                                <MdOutlineNotifications className="option-icon" />
                                <p>Notice</p>
                            </div>
                            <div className="mobile-icons-tab" onClick={MobileLogin}>
                                <img src={ProfileData && ProfileData.profile_pic ? ProfileData.profile_pic : require("../Components/Post/images/male.jpg")} width="32" className="profile-pic-top" />
                                <p>Profile</p>
                            </div>
                        </> : <>
                            <div className="mobile-icons-tab" onClick={isLoggedIn ? MobileLogin : handleLoginShow}>
                                <RiAccountCircleLine className="account-mobile" />
                                <p>Login</p>
                            </div>
                        </>
                    }
                </div>
                {/* <div className="options-container-mobile">
                    <NavLink to="/" >
                        <MdOutlineHome className="option-icon" />
                        <p>home</p>
                    </NavLink>
                    <div onClick={() => Navigate('/auditions')}><MdOutlineMovieCreation className="option-icon" /><p>Auditions</p></div>
                    {isLoggedIn && is24Craft &&
                        <>
                            <div onClick={(e) => { setAddPost(true); }}><BiAddToQueue className="option-icon" /><p>New Post</p></div>
                        </>
                    }
                    {isLoggedIn &&
                        <>
                            <div onClick={handleShow}><MdOutlineNotifications className="option-icon" /><p>Inbox</p></div>
                            <div onClick={MobileLogin}>
                                <img src={ProfileData && ProfileData.profile_pic} width="32" className="profile-pic-top" />
                                <p>Profile</p>
                            </div>
                        </>
                    }
                </div> */}
            </div>
            <Modal size={loginView == "login" ? "md" : "lg"} aria-labelledby="contained-modal-title-vcenter" centered show={loginShow} onHide={handleLoginClose} className="login-popup">
                <Modal.Header closeButton style={{ paddingBottom: "0px" }}>
                    <Tabs
                        id="controlled-tab-example"
                        activeKey={key}
                        onSelect={(k) => setKey(k)}
                        className="modal-tabs"
                    >
                        <Tab eventKey="Login" title="Login"></Tab>
                        <Tab eventKey="Signup" title="Signup"></Tab>
                    </Tabs>
                </Modal.Header>
                <Modal.Body>
                    {key === "Login" ? (
                        <Login handleLoginEvent={handleLoginEvent} />
                    ) : (
                        <Signup handleLoginEvent={handleLoginEvent} />
                    )}
                </Modal.Body>
                {/* <Modal.Footer className="loginfooter">
                    <p>By continuing, you agree to our</p>
                    <a href="/terms-conditions" target="_blank">Terms of service</a>
                    <a href="/privacy-policy" target="_blank">Privacy policy</a>
                </Modal.Footer> */}
            </Modal>
            {AddPost &&
                <NewPost AddPost={AddPost} setAddPost={setAddPost} />
            }
            <Notifications show={show} setShow={setShow} handleClose={handleClose} ProfileData={ProfileData} isRead={isRead} setIsRead={setIsRead} setPage={setPage} page={page} />
        </>
    )
}

export default CustomMobile;