import React, { useState, useEffect } from "react";
import "./Post.css"
import { MdOutlineMoreHoriz } from "react-icons/md";
import Modal from 'react-bootstrap/Modal';
import axiosConfig from "../../Service/axiosConfig"


const Actions = ({ item, handleHideEvent }) => {
    console.log('item:')
    console.log(item)
    let is_own_property = false
    if (item.profile.hcc_id == localStorage.getItem("hcc_id")) {
        is_own_property = true
    }

    const [isModalOpen, setModalOpen] = useState(false);

    const openModal = () => {
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
    };

    const calculateAge = (dob) => {
        const dobDate = new Date(dob);
        const today = new Date();
        let age = today.getFullYear() - dobDate.getFullYear();
        const monthDiff = today.getMonth() - dobDate.getMonth();
        if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < dobDate.getDate())) {
            age--;
        }
        return age;
    };
    const handleDelete = () => {
        axiosConfig.delete(`/accounts/model-images/${item.id}/`).then(res => {
            console.log(res)
            console.log('deleted')
            handleHideEvent()
        }).catch(error => {
            console.log(error.response.data.error)
        })
        closeModal();
    };

    const copyToClipboard = async (copy_item) => {
        console.log(item)
        if (copy_item == "profile") {
            try {
                await navigator.clipboard.writeText(`${item.profile.user.first_name} - ${item.profile.designation} - ${calculateAge(item.profile.dob)} years - ${item.profile.state_city.city}(${item.profile.state_city.state})  https://api.hyderabadcastingclub.com/share/profile/${item.profile.hcc_id}/`);
                console.log('Text copied to clipboard!');
            } catch (err) {
                console.error('Failed to copy text to clipboard:', err);
            }
        } else {
            try {
                console.log(item)
                await navigator.clipboard.writeText(`${item.profile.user.first_name} - ${item.profile.designation} - ${calculateAge(item.profile.dob)} years - ${item.profile.state_city.city}(${item.profile.state_city.state}) https://api.hyderabadcastingclub.com/share/p/${item.uid}/`);
                console.log('Text copied to clipboard!');
            } catch (err) {
                console.error('Failed to copy text to clipboard:', err);
            }
        }

    };



    const shareContent = async (share_to) => {
        try {
            let text = `${item.profile.user.first_name} - ${item.profile.designation} - ${calculateAge(item.profile.dob)} years - ${item.profile.state_city.city}(${item.profile.state_city.state}) https://api.hyderabadcastingclub.com/share/profile/${item.profile.hcc_id}/`
            const shareUrl = encodeURIComponent(text);

            if (share_to == "whatsapp") {
                window.open(`https://api.whatsapp.com/send?text=${shareUrl}`, '_blank');
            } else if (share_to == "fb") {
                window.open(`https://www.facebook.com/sharer/sharer.php?u=https://api.hyderabadcastingclub.com/share/profile/${item.profile.hcc_id}/`, '_blank');
            } else {
                if (navigator.share) {
                    await navigator.share({
                        title: 'Share Post',
                        text: `${item.profile.user.first_name} - ${item.profile.designation} - ${calculateAge(item.profile.dob)} years ${item.profile.state_city.city}(${item.profile.state_city.state}) https://api.hyderabadcastingclub.com/share/p/${item.uid}/`,
                    });
                }
            }
        } catch (err) {
            console.error('Failed to share content:', err);
        }
    };



    return (
        <>
            <div className="dropdown">
                <button type="button" className="btn btn-default" data-bs-toggle="dropdown">
                    <MdOutlineMoreHoriz className="more-icon" />
                </button>
                <ul className="custom-dropdown-menu dropdown-menu">
                    {is_own_property ? (
                        <>
                            <li><a className="dropdown-item" href="#">Edit</a></li>
                            <li><a className="dropdown-item" href="#" onClick={openModal}>Delete</a></li>
                        </>
                    ) : (
                        <>
                            <li><a className="dropdown-item" href="#">Report</a></li>
                            <li><a className="dropdown-item" href="#">Unfollow</a></li>
                        </>
                    )}
                    <li><div className="dropdown-item" onClick={() => copyToClipboard("profile")}>Copy Profile</div></li>
                    <li><div className="dropdown-item" onClick={() => copyToClipboard("post")}>Copy Link</div></li>
                    <li><div className="dropdown-item hide-mobile" onClick={() => shareContent("whatsapp")}>Share to WhatsApp</div></li>
                    <li><div className="dropdown-item hide-mobile" onClick={() => shareContent("fb")}>Share to Facebook</div></li>
                    <li><div className="dropdown-item" onClick={() => shareContent("share")}>Share</div></li>
                    <li><div className="dropdown-item">Block</div></li>
                </ul>
            </div>

            <Modal
                show={isModalOpen}
                onHide={closeModal}
                contentLabel="Delete Confirmation"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body className="success-icon">
                    <h5>Are you sure you want to delete this post?</h5>
                </Modal.Body>
                <Modal.Footer>
                    <button variant="default" className="btn btn-default" onClick={closeModal}>
                        Cancel
                    </button>
                    <button variant="danger" className="btn btn-danger" onClick={handleDelete}>
                        Delete
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
export { Actions };