import React, { useState, useEffect } from "react";
import "./Post.css"
import axiosConfig from "../../Service/axiosConfig"
import { Shortlist } from "../ShortList/ShortList";


const ActionsDp = ({ item }) => {
    return (
        <>
            <dpostiv className="actionsdp-list">
                <a className="btn btn-default d-flex" style={{ alignItems: 'center', gap: '5px' }}>
                    <b>{item.views ? item.views : 0}</b> views
                </a>
                <a className="btn btn-default d-flex" style={{ alignItems: 'center', gap: '5px' }}>
                    <b>{item.total_posts ? item.total_posts : 0}</b> Posts
                </a>
                <Shortlist ShortlistId={item.id} />
            </dpostiv>
            {/* <div className="actionsdp-list row">
                <div className="col-4">
                    <button className="btn btn-default d-flex">
                        <b>{item.views ? item.views : 0}</b> views
                    </button>
                </div>
                <div className="col-4">
                    <button className="btn btn-default d-flex">
                        <b>{item.views ? item.views : 0}</b> Posts
                    </button>
                </div>
                <div className="col-4">
                    <Shortlist ShortlistId={item.id} />
                </div>
            </div> */}
        </>
    )
}
export { ActionsDp };